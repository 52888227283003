import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Button, Col, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import pagionationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { TiExportOutline } from 'react-icons/ti';
import { FaRegPlusSquare, FaRegCheckCircle, FaSync, FaRegQuestionCircle } from 'react-icons/fa';
import { AiOutlineWarning } from 'react-icons/ai';
import { ImCancelCircle } from 'react-icons/im';
import { GiReturnArrow } from 'react-icons/gi';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { openCreateEditReturn, setEndDate, setReturnInspectionFilter, setReturnStatusFilter, setStartDate } from '../../redux/actions';
import moment from 'moment';
import './Returns.css';
import returnsService from '../../services/returns.service';
import ReturnModel from '../../models/return.model';
import CreateEditReturn from './CreateEditReturn';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';

const { SearchBar } = Search;

const Returns = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [statusOptions] = useState([
    { value: 'all', label: 'All Statuses' },
    { value: 1, label: 'Complete' },
    { value: 0, label: 'In Process' },
    { value: 3, label: 'Not Found' },
  ]);

  const [inspectionOptions] = useState([
    { value: 'all', label: 'All Inspections' },
    { value: 'plc', label: 'Correct / New' },
    { value: 'pdu', label: 'Defective / Used' },
    { value: 'ifmm', label: 'Incorrect / Fraud / Missing' },
  ]);

  const returnStatusFilter = useSelector(state => state.return_status_filter);
  const returnInspectionFilter = useSelector(state => state.return_inspection_filter);
  const user = useSelector(state => state.user);
  const startDate = useSelector(state => state.start_date);
  const endDate = useSelector(state => state.end_date);
  const openCreateEditReturnToggle = useSelector(state => state.open_create_edit_return);

  const pagination = pagionationFactory({
    sizePerPage: 35,
    hideSizePerPage: true
  });

  const getData = async (createNew, status, inspection) => {
    try {
      const results = await returnsService.getReturns(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), status, inspection, user);
      const newRows = results.map(r => new ReturnModel(r));
      setRows(newRows);
      createNew ? dispatch(openCreateEditReturn()) : null;
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Button size="sm" color="outline-secondary" onClick={handleClick}><TiExportOutline style={{fontSize: '20px', marginBottom: '2px'}}/></Button>
      </div>
    );
  };

  useEffect(() => {
    const newColumns = [
      {
        text: 'LPN',
        dataField: 'lpn',
        headerStyle: {width: '130px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Created TS',
        dataField: 'created_ts',
        headerStyle: {width: '150px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'desc') {
            return new Date(b) - new Date(a);
          }
          return new Date(a) - new Date(b);
        },
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'SKU',
        dataField: 'sku',
        headerStyle: {width: '160px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'ASIN',
        dataField: 'asin',
        headerStyle: {width: '110px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'UPC/EAN',
        dataField: 'upc_ean',
        headerStyle: {width: '140px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Vendor',
        dataField: 'vendor_name',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'}),
      },
      {
        text: 'Brand',
        dataField: 'brand',
        headerStyle: {width: '120px'},
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Order ID',
        dataField: 'order_id',
        headerStyle: {width: '150px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Description',
        dataField: 'product_description',
        style: {textAlign: 'left', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'})
      },
      {
        text: 'Image',
        dataField: 'image',
        headerStyle: {width: '110px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'}),
        formatter: (cell, row) => {
          return (
            <img src={row.image} className="table-image" />
          );
        }
      },
      {
        text: 'Cost',
        dataField: 'cost',
        headerStyle: {width: '80px'},
        style: {textAlign: 'right', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'}),
      },
      {
        text: 'Price',
        dataField: 'price',
        headerStyle: {width: '80px'},
        style: {textAlign: 'right', verticalAlign: 'middle'},
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        filter: textFilter({className: 'filter-box'}),
      },
      {
        text: 'Inspection',
        dataField: 'product_insepection',
        headerStyle: {width: '95px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        filter: textFilter({className: 'filter-box'}),
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        formatter: (cell, row) => {
          if (row.product_inspection === 'plc') {
            return <FaRegCheckCircle data-tip="Correct / New" style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else if (row.product_inspection === 'pdu') {
            return <AiOutlineWarning data-tip="Defective / Used" style={{fontSize: '20px', marginBottom: '2px', color: 'orange'}}/>;
          } else if (row.product_inspection === 'ifmm') {
            return <ImCancelCircle data-tip="Incorrect / Fraud / Missing" style={{fontSize: '18px', marginBottom: '2px', color: 'red'}}/>;
          }
        }
      },
      {
        text: 'Complete',
        dataField: 'internal_status',
        headerStyle: {width: '80px'},
        style: {textAlign: 'center', verticalAlign: 'middle'},
        filter: textFilter({className: 'filter-box'}),
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row) => dispatch(openCreateEditReturn(row))
        },
        formatter: (cell, row) => {
          if (row.complete === 1) {
            return <FaRegCheckCircle data-tip="Complete" style={{fontSize: '18px', marginBottom: '2px', color: 'green'}}/>;
          } else if (row.not_found === 1) {
            return <FaRegQuestionCircle data-tip="Not Found" style={{fontSize: '18px', marginBottom: '2px', color: '#00008b'}}/>;
          } else if (row.complete === 0) {
            return <FaSync data-tip="In Process" style={{fontSize: '18px', marginBottom: '2px', color: 'orange'}}/>;
          }
        }
      }
    ];

    ReactTooltip.rebuild();
    setColumns(newColumns);
  }, [rows, setColumns]);

  useEffect(async () => {
    setIsLoading(true);
    getData();
  }, [startDate, endDate]);

  useEffect(async () => {
    dispatch(setReturnStatusFilter(statusOptions[0]));
    dispatch(setReturnInspectionFilter(inspectionOptions[0]));
  }, []);

  const selectStatus = (e) => {
    dispatch(setReturnStatusFilter(e));
    getData(false, e.value, returnInspectionFilter.value);
  };

  const selectInspectioon = (e) => {
    dispatch(setReturnInspectionFilter(e));
    getData(false, returnStatusFilter.value, e.value);
  };

  const customStyles = {
    control: base => ({
      ...base,
      height: 34,
      minHeight: 34,
    }),
    singleValue: (provided) => {
      return { ...provided, paddingBottom: '5px' };
    }
  };

  const defaultSort = [{
    dataField: 'created_ts',
    order: 'desc'
  }];

  if (isLoading) {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        <Row>
          <Col lg={12} style={{textAlign: 'center', marginTop: '20em'}}>
            <PulseLoader sizeUnit={'px'} size={20} color={'#419bf7'} />
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{marginTop: '10px'}}>
        { openCreateEditReturnToggle &&
          <CreateEditReturn isOpen={openCreateEditReturnToggle} refresh={getData} />
        }
        <ReactTooltip />
        <Row style={{height: 'calc(100vh - 100px)', overflow: 'auto'}}>
          <Col lg={12} className="view-table">
            { columns &&
              <ToolkitProvider keyField="id" data={rows} columns={columns} search bootstrap4 exportCSV={{exportAll: false, onlyExportFiltered: true}}>
                {
                  props => (
                    <div>
                      <div style = {{ float: 'left', paddingTop: '4px' }}>
                        <div>
                          <GiReturnArrow className="text-primary" style={{marginRight: '5px', marginBottom: '6px', fontSize: '20px'}} />
                          <span style={{fontSize: '18px', fontStyle: 'italic', fontWeight: '500'}}>Returns</span>
                        </div>
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <MyExportCSV { ...props.csvProps } />
                      </div>
                      <div style = {{ float: 'right', marginLeft: '5px' }}>
                        <Button size="sm" style={{paddingRight: '10px', paddingLeft: '10px', paddingBottom: '5px', paddingTop: '5px'}} color="outline-primary" onClick={() => dispatch(openCreateEditReturn())}><FaRegPlusSquare style={{fontSize: '15px', marginBottom: '3px'}}/></Button>
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font" 
                          selected={endDate}
                          onChange={(date) => dispatch(setEndDate(date))}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      <div style = {{ float: 'right', width: '120px', marginLeft: '5px' }}>
                        <DatePicker
                          className="form-control date-picker-font"
                          style={{ fontSize: '13px' }}
                          selected={startDate}
                          onChange={(date) => dispatch(setStartDate(date))}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={returnInspectionFilter} styles={customStyles} name="inspections" options={inspectionOptions} onChange={(e) => selectInspectioon(e)}/>
                      </div>
                      <div style={{float: 'right', marginLeft: '10px', width: '150px'}} >
                        <Select height={10} className="dropdown" value={returnStatusFilter} styles={customStyles} name="statuses" options={statusOptions} onChange={(e) => selectStatus(e)}/>
                      </div>
                      <div style = {{ float: 'right', width: '200px' }}>
                        <SearchBar className="form-control" style={{fontSize: '13px'}} { ...props.searchProps } />
                      </div>
                      <BootstrapTable  classes="table-scroll" {...props.baseProps} rowClasses="list-row" pagination={pagination} defaultSorted={defaultSort} condensed bordered={false} filter={filterFactory()} noDataIndication={() => (<div>There are currently no returns</div>)}/>
                    </div>
                  )
                }
              </ToolkitProvider>
            }
          </Col>
        </Row>
      </div>
    );
  }
};

export default Returns;